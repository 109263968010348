import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// component
import { Navbar } from "../components/Navbar";
import { Sketches } from "../components/Sketches";
import { TagSelector } from "../components/TagSelector";

// infrastructure
import {
  isSigningInToSave,
  clearSigningInToSave,
} from "../infrastructure/localstorage";

// infrastructure
import { SortTypes } from "../infrastructure/sketchRepository";

// template
import { Main } from "../templates/Main";

// state
import { useRecoilValue } from "recoil";
import { authState } from "../states/authState";

const sortOptions: { label: string; value: SortTypes }[] = [
  { label: "新着順", value: "latest" },
  { label: "作成順", value: "oldest" },
];

export const Gallery = () => {
  const navitage = useNavigate();

  // URLで指定したuid
  const { uid: paramsUid } = useParams();
  const auth = useRecoilValue(authState);

  const [sort, setSort] = React.useState<SortTypes>("latest");
  const [tags, setTags] = React.useState<string[]>([]);

  // ユーザーが指定されている場合は、そのユーザーのスケッチを表示する
  const uid = paramsUid;
  const mySketch = paramsUid === auth.uid;

  useEffect(() => {
    if (isSigningInToSave()) {
      clearSigningInToSave();
      navitage("/editor");
    }
  }, [navitage]);

  const onChangeSort = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSort(e.target.value as SortTypes);
  };

  const onChangeTags = (tags: string[]) => {
    setTags(tags);
  };

  const toolBarLeft = (
    <div className="d-flex">
      <div className="btn-group me-3" role="group">
        {sortOptions.map((option) => (
          <div key={`sort-${option.value}`}>
            <input
              type="radio"
              className="btn-check"
              name="sort"
              id={`sort-${option.value}`}
              value={option.value}
              checked={option.value === sort}
              onChange={onChangeSort}
            />
            <label
              className="btn btn-outline-primary  btn-sm"
              htmlFor={`sort-${option.value}`}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>

      <div>タグ:</div>
      <TagSelector
        value={tags}
        allowNewTags={false}
        onChange={(tags) => onChangeTags(tags)}
      />
    </div>
  );

  const toolBarCenter = <></>;
  const toolBarRight = <></>;

  return (
    <Main
      navbar={<Navbar center={toolBarLeft} />}
      contents={
        <div id="sketches-container">
          <Sketches uid={uid} mySketch={mySketch} sort={sort} tags={tags} />
        </div>
      }
    />
  );
};
