import { atom, selector, useRecoilState } from "recoil";

import { Tags, TagColors } from "../domain/tags";

export const tagsState = atom({
  key: "tags",
  default: [] as Tags,
});

export const tagColorsState = atom({
  key: "tagColors",
  default: {} as TagColors,
});
