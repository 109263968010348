import React, { useEffect, useState } from "react";

import { httpsCallable } from "firebase/functions";
import { getFunctions } from "../infrastructure/firebase";
import { getDatabase, ref, onValue } from "firebase/database";
import { OpenaiTypes } from "../infrastructure/functions-types/types";

// state
import { useRecoilState } from "recoil";
import { authState } from "../states/authState";

let unsubscribed: undefined | (() => void);

export const useCodeAssist = (onUpdateCode: (code: string) => void) => {
  const [{ uid }] = useRecoilState(authState);

  const [generating, setGenerating] = useState(false);

  const submitPrompt = async (prompt: string) => {
    if (typeof unsubscribed !== "undefined") return;
    setGenerating(true);

    const time = new Date().getTime();

    const data: OpenaiTypes = {
      prompt,
      time,
    };
    const db = getDatabase();

    try {
      unsubscribed = onValue(
        ref(db, `Users/${uid}/Autocode/${time}`),
        (snapshot) => {
          const data = snapshot.val();
          onUpdateCode(data?.code ?? "");

          if (data?.done) {
            // catch done
          }
        }
      );
      await httpsCallable(getFunctions(), "openai")(data);
      unsubscribed();
      unsubscribed = undefined;
      setGenerating(false);
    } catch (e) {
      console.log({ e });
      setGenerating(false);
    }
  };

  return { submitPrompt, generating };
};
