import React, { useEffect } from "react";
import { useBootStrapModal } from "../hooks/useModal";

import {
  getAuth,
  EmailAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import "firebaseui/dist/firebaseui.css";

// components
import { Modal } from "./Modal";

// State
import { useRecoilValue } from "recoil";
import { authState } from "../states/authState";

type PropsType = {
  signInPurpose?: string;
  sketchId?: string;
  id: string;
  onCloseReq?: () => void;
};

export const SigninModal = ({
  id,
  onCloseReq,
  signInPurpose,
  sketchId,
}: PropsType) => {
  const [openModal, closeModal] = useBootStrapModal(id, onCloseReq);
  useEffect(() => {
    openModal();
  }, []);

  const auth = useRecoilValue(authState);

  const signInSuccessUrl =
    signInPurpose === "tosave"
      ? "/editor"
      : signInPurpose === "toedit"
      ? `/editor/${sketchId}`
      : "/";
  const signInMessage =
    signInPurpose === "tosave"
      ? "サインインしてスケッチを保存する。"
      : signInPurpose === "toedit"
      ? "サインインしてコードを表示"
      : "";

  useEffect(() => {
    const firebaseui = require("firebaseui");

    let ui = firebaseui.auth.AuthUI.getInstance();

    if (!ui) {
      ui = new firebaseui.auth.AuthUI(getAuth());
    }

    const microsoft_provider = new OAuthProvider("microsoft.com");
    const github_provider = new OAuthProvider("github.com");

    ui.start("#firebaseui-container", {
      signInSuccessUrl: signInSuccessUrl,
      signInFlow: "popup",

      signInOptions: [
        EmailAuthProvider.PROVIDER_ID,
        GoogleAuthProvider.PROVIDER_ID,
        microsoft_provider.providerId,
        github_provider.providerId,
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult: any, redirectUrl: any) => {
          return false;
        },
      },
    });
  }, [signInSuccessUrl]);

  useEffect(() => {
    console.log(auth.isSignin);
    if (auth.isSignin) {
      closeModal();
    }
  }, [auth.isSignin]);

  return (
    <Modal title="サインイン/サインアップ" id={id}>
      <div className="">
        <div>{signInMessage}</div>
        <div id="firebaseui-container"></div>
      </div>
    </Modal>
  );
};
