import { initializeApp, getApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";
import {
  getFunctions as getFunctions_,
  connectFunctionsEmulator,
  Functions,
} from "firebase/functions";
import { getDatabase, connectDatabaseEmulator } from "@firebase/database";
import { getStorage, connectStorageEmulator } from "firebase/storage";

// use after initializeApp
export const enableEmulators = (): void => {
  connectAuthEmulator(getAuth(), "http://localhost:9099");
  connectFirestoreEmulator(getFirestore(), "localhost", 8080);
  connectFunctionsEmulator(getFunctions(), "localhost", 5001);
  connectDatabaseEmulator(getDatabase(), "localhost", 9000);
  connectStorageEmulator(getStorage(), "localhost", 9199);
  connectStorageEmulator(
    getStorage(getApp(), "p5-hub-profile"),
    "localhost",
    9199
  );
};

export const initializeFirebase = (): void => {
  const firebaseConfig = {
    apiKey: "AIzaSyBAU-tyiFyDrR51kb7xKBtUzP6nJR-HFi4",
    authDomain: "p5-hub.firebaseapp.com",
    databaseURL:
      "https://p5-hub-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "p5-hub",
    storageBucket: "p5-hub.appspot.com",
    messagingSenderId: "654592251114",
    appId: "1:654592251114:web:d8eced8d9e97c0dfd13393",
  };

  initializeApp(firebaseConfig);

  const runningOnJest = typeof window !== "object";
  if (runningOnJest) {
    // Jest
    enableEmulators();
  } else {
    // Emulator
    const runningOnLocalhost = window.location.hostname === "localhost";
    if (runningOnLocalhost) {
      enableEmulators();
    }
  }
};

export const getFunctions = (): Functions => {
  return getFunctions_(getApp(), "asia-northeast1");
};

// try {
//   if (!runningOnJest) enableIndexedDbPersistence(firestore);
// } catch (error) {
//   console.error(error);
// }
