import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Split from "react-split";

// domain
import {
  LocalSketch,
  SketchFile,
  SketchInfo,
  initialLocalSketch,
} from "../domain/sketch";

// component
import { Navbar } from "../components/Navbar";
import { CodeEditor } from "../components/CodeEditor";
import { Console } from "../components/Console";
import { Preview } from "../components/Preview";
import { ToolBarButton } from "../components/ToolBarButton";
import { Qrode } from "../components/Qrcode";
import { useFullScreen } from "../components/FullScreen";

import { AssetsModal } from "../modals/AssetsModal";
// modals
import { SketchSettingsModal } from "../modals/SketchSettingsModal";
import { SigninModal } from "../modals/SigninModal";

// hooks
import { useScreenshot } from "../hooks/useScreenshot";
import { useConsole } from "../hooks/useConsole";

// template
import { Main } from "../templates/Main";
import { FullScreen } from "../templates/FullScreen";

// infrastructure
import {
  storeNewSketch,
  updateSketch,
  getSketchInfoFromSketchId,
  getSketchMainJs,
  getThumbnailUrl,
} from "../infrastructure/sketchRepository";

import * as localStorage from "../infrastructure/localstorage";
import { APP_SETTINGS } from "../setting";

// state
import { useRecoilState } from "recoil";
import { authState } from "../states/authState";
import { userState } from "../states/userState";

export const Editor = () => {
  const [playState, setPlayState] = useState<boolean>(false);

  const [auth] = useRecoilState(authState);
  const [user] = useRecoilState(userState);

  const [sketchInfo, setSketchInfo] = useState<SketchInfo | undefined>(
    undefined
  );
  const [sketch, setSketch] = useState<LocalSketch>({
    ...initialLocalSketch,
    sketchId: useParams()["sketchId"],
  });
  const [previewCode, setPreviewCode] = useState<string>("");
  const [readonly, setReadonly] = useState<boolean>(false);
  const { enterFullScreen, exitFullScreen, isFullScreen } = useFullScreen();
  const [saveState, setSaveState] = useState<"saved" | "saving" | "modified">(
    "modified"
  );
  const [thumbnailUrl, setThumbnailUrl] = useState("/noimage.png");

  const [isSigninModalOpened, setSigninModalOpened] = useState(false);
  const [isAssetsModalOpened, setAssetsModalOpened] = useState(false);
  const [isSketchSettingsModalOpened, setSketchSettingsModalOpened] =
    useState(false);

  useEffect(() => {
    if (auth.isSignin && sketch.sketchId) {
      // サインインしていてsketchIdが指定されていればスケッチをロードする
      (async () => {
        if (sketch.sketchId === undefined) return;
        const sketchInfo = await getSketchInfoFromSketchId(
          sketch.sketchId,
          auth.uid
        );
        setSketchInfo(sketchInfo);

        const code = await getSketchMainJs(sketchInfo.uid, sketchInfo.sketchId);
        const {
          createdAt,
          updatedAt,
          uid,
          uname,
          hasProfileImg,
          ...localSketch
        } = { ...sketchInfo, code, thumbnail: undefined };
        setSketch(localSketch);

        const thumbnailUrl = await getThumbnailUrl(auth.uid, sketch.sketchId);
        setThumbnailUrl(thumbnailUrl);

        setReadonly(sketchInfo.uid === auth.uid ? false : true);
      })();
    } else if (auth.isSignin && localStorage.isSigningInToSave()) {
      // 保存するためにサインインした場合
      // ローカルストレージに保存されているスケッチをロードする
      localStorage.clearSigningInToSave();
      saveSketch();
      setSigninModalOpened(false);
      // } else if (localStorage.hasCode()) {
      //   // 既存のスケッチのロードではなく、ローカルストレージに保存されている場合
      //   // ユーザーに尋ねて、ローカルストレージに保存されているスケッチをロードする
      //   if (window.confirm("作成中のスケッチがあります。読み込みますか？")) {
      //     const localStorageSketch = localStorage.loadCode();
      //     if (localStorageSketch) {
      //       localStorageSketch.sketchId
      //       setSketch(localStorageSketch);
      //     }
      //   } else {
      //     // 読み込まない場合は破棄する。
      //     localStorage.deleteCode();
      //   }
    }
  }, [sketch.sketchId, auth]);

  const saveSketch = async () => {
    const files: SketchFile[] = [
      { filename: "main.js", contents: new Blob([sketch.code]) },
    ];
    if (sketch.thumbnail) {
      files.push({
        filename: "thumbnail.png",
        contents: sketch.thumbnail,
      });
    }
    if (sketch.sketchId) {
      // スケッチIDがある場合には既存のスケッチを更新する
      setSaveState("saving");
      const { code, sketchId, shortId, thumbnail, ...updatedSketch } = {
        ...sketch,
        files,
      };
      updateSketch(auth.uid, sketch.sketchId, updatedSketch).then(() => {
        localStorage.deleteCode();
        setSaveState("saved");
      });
    } else if (auth.isSignin) {
      // スケッチIDがなくてサインインされている場合は新規保存
      setSaveState("saving");
      const { code, sketchId, shortId, thumbnail, ...newSketch } = {
        ...sketch,
        files,
        uid: auth.uid,
        uname: user.uname,
        hasProfileImg: user.hasProfileImg,
      };

      const newSketchId = await storeNewSketch(newSketch);
      setSketch({ ...sketch, sketchId: newSketchId });
      localStorage.deleteCode();
      setSaveState("saved");
    } else {
      // サインインしていない場合は、ローカルストレージに保存し、ログインさせる。
      // localStorage.saveCode(sketch);
      localStorage.setSigningInToSave();
      setSigninModalOpened(true);
    }
  };

  // スケッチの変更をローカルストレージに保存する
  const modifySketch = (modification: Partial<LocalSketch>) => {
    const _sketch = { ...sketch, ...modification };
    setSketch(_sketch);
    localStorage.saveCode(_sketch);
    setSaveState("modified");
  };

  const onChangeTitle = (title: string) => {
    modifySketch({ title });
  };

  const toolBarLeft = (
    <>
      {!readonly && (
        <ToolBarButton
          onClick={saveSketch}
          disabled={saveState !== "modified"}
          icon="save"
        >
          保存
        </ToolBarButton>
      )}

      <input
        className="text"
        type="text"
        value={sketch.title}
        onChange={(e) => onChangeTitle(e.target.value)}
      />
    </>
  );

  // 実行・停止・全画面表示
  const play = () => {
    clearMessages();
    setPreviewCode(sketch.code);
    setPlayState(true);
  };

  const stop = () => {
    setPreviewCode("");
    setPlayState(false);
  };

  const playFullScreen = () => {
    enterFullScreen();
    play();
  };

  const toolBarCenter = (
    <>
      <ToolBarButton onClick={play}>
        <i className="bi bi-play"></i>
      </ToolBarButton>
      <ToolBarButton onClick={stop}>
        <i className="bi bi-stop"></i>
      </ToolBarButton>
    </>
  );

  const toAssets = () => {
    // 一旦セーブする
    saveSketch();
    setAssetsModalOpened(true);
  };

  // スクリーンショット関係
  const onChangeScreeshot = (screenshot: Blob | null) => {
    if (!screenshot) return;
    modifySketch({ thumbnail: screenshot, hasThumbnail: true });
  };

  const takeScreenshot = useScreenshot(onChangeScreeshot);

  const toolBarRight = (
    <>
      <ToolBarButton icon="card-image" onClick={toAssets}>
        外部ファイル
      </ToolBarButton>
      <ToolBarButton
        icon="gear"
        onClick={() => setSketchSettingsModalOpened(true)}
      >
        設定
      </ToolBarButton>
    </>
  );

  // 本当はsetSketchをわたしたほうがよい？
  const onChangeCode = (code: string) => {
    modifySketch({ code });
  };

  const { getMessages, clearMessages } = useConsole();

  const contents = (
    <>
      <Split
        className="split h-100 d-flex flex-column"
        id="editor-container"
        direction="vertical"
        sizes={[70, 30]}
      >
        <Split className="split" sizes={[40, 60]}>
          <div className="d-flex flex-column h-100" id="editor-pane">
            <CodeEditor
              defaultCode={sketch.code}
              onChange={onChangeCode}
              readonly={readonly}
            />
          </div>

          <div className="p-0 d-flex flex-column" id="preview-pane">
            <div className="p-3 d-flex">
              <div className="flex-fill">Preview</div>
              <img
                src={
                  sketch.thumbnail
                    ? URL.createObjectURL(sketch.thumbnail) // thumnailが更新されていたら表示
                    : thumbnailUrl
                }
                alt="screenshot"
                id="screenshot-thumbnail-icon"
              />
              <ToolBarButton
                onClick={takeScreenshot}
                icon="camera"
                disabled={!playState}
              >
                スクリーンショット
              </ToolBarButton>
              <ToolBarButton onClick={playFullScreen} icon="fullscreen">
                全画面表示
              </ToolBarButton>
            </div>
            <div className="flex-fill overflow-scroll d-flex flex-column justify-content-center align-items-center">
              <div className="m-0">
                <Preview
                  uid={sketchInfo?.uid}
                  sketchId={sketchInfo?.sketchId}
                  code={previewCode}
                  plugins={sketch.plugins}
                  play={playState}
                />
              </div>
            </div>
          </div>
        </Split>
        <div className="w-100" id="console-pane">
          <Console messages={getMessages()} />
        </div>
      </Split>
      {isAssetsModalOpened && (
        <AssetsModal
          uid={auth.uid}
          sketchId={sketch.sketchId ?? ""}
          id="modal-assets"
          onCloseReq={() => setAssetsModalOpened(false)}
        />
      )}

      {isSketchSettingsModalOpened && (
        <SketchSettingsModal
          id="modal-settings"
          defaultValue={sketch}
          onChange={(sketchSettings) => {
            modifySketch(sketchSettings);
          }}
          onCloseReq={() => setSketchSettingsModalOpened(false)}
        />
      )}
      {isSigninModalOpened && (
        <SigninModal
          id="modal-signin-editor"
          onCloseReq={() => setSigninModalOpened(false)}
        />
      )}
    </>
  );

  const [largeQr, setLargeQr] = useState<boolean>(false);

  // フルスクリーンモード
  if (isFullScreen)
    return (
      <FullScreen
        sidemenuContent={
          <div className="d-flex flex-column w-100 h-100 px-5 py-5">
            <div
              className="text-center"
              onClick={() => {
                setLargeQr(!largeQr);
              }}
            >
              {sketch.shortId && (
                <Qrode
                  url={APP_SETTINGS.fullScreenUrl + sketch.shortId}
                  size={220}
                />
              )}
            </div>
            <div className="pb-5 text-center">
              <a href={APP_SETTINGS.fullScreenUrl + sketch.shortId}>
                {APP_SETTINGS.fullScreenUrl + sketch.shortId}
              </a>
            </div>
            <div>
              <h2>{sketch.title}</h2>
              <p>{sketch.desc}</p>
              <p>
                {sketch.tags.map((tag) => (
                  <span
                    className="badge bg-secondary me-1 sketch-tag"
                    key={`sketch-${tag}`}
                  >
                    {tag}
                  </span>
                ))}
              </p>
            </div>
            <div className="text-end">
              <ToolBarButton onClick={exitFullScreen}>戻る</ToolBarButton>
            </div>
          </div>
        }
        onClose={exitFullScreen}
      >
        <div className="d-flex h-100 w-100 align-items-center justify-content-center">
          <Preview
            code={previewCode}
            plugins={sketch.plugins}
            play={playState}
          />
        </div>
      </FullScreen>
    );

  return (
    <Main
      navbar={
        <Navbar
          left={toolBarLeft}
          center={toolBarCenter}
          right={toolBarRight}
        />
      }
      contents={contents}
    />
  );
};
