import React, { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";

import "./App.scss";

import { Landing } from "./pages/Landing";
import { Gallery } from "./pages/Gallery";
import { Editor } from "./pages/Editor";
import { Viewer } from "./pages/Viewer";

import { AuthWatcher } from "./components/AuthWatcher";

// components
import { UpdateModal } from "./modals/UpdateModal";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/gallery",
    element: <Gallery />,
  },
  {
    path: "/user/:uid",
    element: <Gallery />,
  },
  {
    path: "/user",
    element: <Gallery />,
  },
  {
    path: "/editor",
    element: <Editor />,
  },
  {
    path: "/editor/:sketchId",
    element: <Editor />,
  },
  {
    path: "/viewer/:sketchId",
    element: <Viewer />,
  },
  {
    path: "/f/:shortId",
    element: <Viewer viewmode="fullscreen" />,
  },
]);

function App() {
  const [showUpdate, setShowUpdate] = useState(true);

  return (
    <RecoilRoot>
      <AuthWatcher />
      <RouterProvider router={router} />
      {showUpdate && (
        <UpdateModal
          id="modal-update"
          onCloseReq={() => setShowUpdate(false)}
        />
      )}
    </RecoilRoot>
  );
}
export default App;
