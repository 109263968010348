import { atom } from "recoil";

import { defaultUser } from "../domain/user";

// repository
import { updateUserInfo } from "../infrastructure/userRepository";
import { authState } from "./authState";

const userState = atom({
  key: "user",
  default: defaultUser,
  effects: [
    ({ onSet, getPromise }) => {
      onSet(async (newValue, oldValue, isReset) => {
        const auth = await getPromise(authState);
        if (auth.isSignin === false) return;
        updateUserInfo(auth.uid, newValue);
        // TODO: called at first load
      });
    },
  ],
});

const profileIconState = atom({ key: "profileIcon", default: "/profile.png" });

export { userState, profileIconState };
