import { useState, useEffect } from "react";

const bootstrap = require("bootstrap");

export const useBootStrapModal = (id: string, onHidden?: () => void) => {
  const [modal, setModal] = useState<any>(null);

  const onHiddenModal = () => {
    console.log("onHiddenModal");
    onHidden && onHidden();
  };

  const openModal = () => {
    const element = document.getElementById(id);
    if (!element) return;

    const modal = new bootstrap.Modal(document.getElementById(id), {});
    setModal(modal);
    modal?.show();
    element.addEventListener("hidden.bs.modal", onHiddenModal);
  };

  const closeModal = () => {
    if (!modal) return;

    modal?.hide();
  };

  useEffect(() => {
    return () => {
      const element = document.getElementById(id);
      element?.removeEventListener("hidden.bs.modal", onHiddenModal);
    };
  }, []);

  return [openModal, closeModal];
};
