import { useCallback, useEffect } from "react";

// infrastructure
import { getUser, getProfileIcon } from "../infrastructure/userRepository";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../states/authState";
import { setTagSnapshotListener } from "../infrastructure/tagRepository";

// state
import { useSetRecoilState } from "recoil";
import { userState } from "../states/userState";
import { profileIconState } from "../states/userState";
import { tagsState, tagColorsState } from "../states/tagsState";

// domain
import { Tags, TagColors } from "../domain/tags";

export const AuthWatcher = () => {
  const { signin, signout } = useAuth();
  const setUser = useSetRecoilState(userState);
  const setProfileIcon = useSetRecoilState(profileIconState);

  const setTags = useSetRecoilState(tagsState);
  const setTagColors = useSetRecoilState(tagColorsState);

  useEffect(() => {
    onAuthStateChanged(getAuth(), async (authUser) => {
      if (authUser) {
        if (authUser.email === null) throw new Error("email is not provieded");
        signin(authUser.uid, authUser.email);
        const user = await getUser(authUser.uid);
        setUser(user);

        if (user.hasProfileImg) {
          const profileIcon = await getProfileIcon(authUser.uid);
          setProfileIcon(profileIcon);
        }
      } else {
        signout();
      }
    });

    const onTagsData = (tagsData: Tags) => {
      setTags(tagsData);
    };

    const onTagCloorsData = (tagColorsData: TagColors) => {
      setTagColors(tagColorsData);
    };

    return setTagSnapshotListener(onTagsData, onTagCloorsData);
  }, []); // do not add signin and signout to dependencies

  return <></>;
};
