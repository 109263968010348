import { useEffect, useState } from "react";

export const useFullScreen = (initialFullScreen: boolean = false) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(initialFullScreen);

  const enterFullScreen = () => {
    setIsFullScreen(true);
    document.body.requestFullscreen();
  };

  const exitFullScreen = () => {
    setIsFullScreen(false);
    document.exitFullscreen();
  };
  return { enterFullScreen, exitFullScreen, isFullScreen };
};
