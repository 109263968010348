import { useState, useEffect } from "react";

type Props = {
  id: string;
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  large?: boolean;
};

export const Modal = ({
  id,
  title,
  children,
  footer,
  large = false,
}: Props) => {
  const [isShown, setShownState] = useState(false);

  const onModalShown = () => setShownState(true);
  const onModalHidden = () => setShownState(false);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;
    element.addEventListener("show.bs.modal", onModalShown);
    element.addEventListener("hidden.bs.modal", onModalHidden);

    return () => {
      element.removeEventListener("show.bs.modal", onModalShown);
      element.removeEventListener("hidden.bs.modal", onModalHidden);
    };
  }, [id]);

  return (
    <div className="modal fade" id={id} tabIndex={-1}>
      <div
        className={
          "modal-dialog modal-dialog-centered modal-dialog-scrollable" +
          (large ? " modal-lg" : "")
        }
      >
        <div className="modal-content box">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          {footer && <div className="modal-footer">{footer}</div>}
        </div>
      </div>
    </div>
  );
};
