export const activeEditor = () => {
  // editorのレイヤーを最前面にする
  const editorPane = document.getElementById("editor-pane");
  if (editorPane) {
    editorPane.style.zIndex = "300";
  }
};

export const activePreview = () => {
  // editorのレイヤーを最前面にする
  const editorPane = document.getElementById("editor-pane");
  if (editorPane) {
    editorPane.style.zIndex = "100";
  }
};
