import React, { useEffect, useMemo } from "react";
import { useBootStrapModal } from "../hooks/useModal";

// components
import { Modal } from "./Modal";

// infrastructures
import {
  getLastGetReleaseNote,
  setLastGetReleaseNote,
} from "../infrastructure/localstorage";
import "../infrastructure/localstorage";

// State
import { useRecoilValue } from "recoil";
import { authState } from "../states/authState";

// data
import releaseNote from "../releaseNote.json";

type PropsType = {
  id: string;
  onCloseReq?: () => void;
};

export const UpdateModal = ({ id, onCloseReq }: PropsType) => {
  const [openModal, closeModal] = useBootStrapModal(id, onCloseReq);
  const auth = useRecoilValue(authState);

  useEffect(() => {
    if (auth.isSignin) {
      openModal();
    }
  }, [auth.isSignin]);

  const lastGetReleaseNote = getLastGetReleaseNote();

  const releaseNoteShown = useMemo(() => {
    const showNote = releaseNote.filter((item) => {
      const releaseDate = new Date(item.date);
      return lastGetReleaseNote < releaseDate;
    });
    return showNote;
  }, [lastGetReleaseNote]);

  useEffect(() => {});

  const hasUpdate = releaseNoteShown.length > 0;

  return hasUpdate ? (
    <Modal
      title="更新情報"
      id={id}
      large
      footer={
        <button
          className="btn btn-secondary"
          onClick={() => {
            closeModal();
            setLastGetReleaseNote();
          }}
        >
          閉じる
        </button>
      }
    >
      {releaseNoteShown.map((item) => {
        return (
          <div key={item.version}>
            <h3>{item.version}</h3>
            <p>{item.contents}</p>
          </div>
        );
      })}
    </Modal>
  ) : (
    <></>
  );
};
