import React, { useEffect, useState } from "react";

import { SketchInfo } from "../domain/sketch";

// infrastructure
import {
  querySketchInfos,
  SortTypes,
  deleteSketch,
} from "../infrastructure/sketchRepository";

// domain
import { Tags } from "../domain/tags";

// components
import { SketchItem } from "./SketchItem";

type Props = {
  uid?: string;
  sort?: SortTypes;
  tags?: Tags;
  mySketch?: boolean;
};

export const Sketches = ({ uid, sort, tags, mySketch = false }: Props) => {
  const [sketchInfos, setSketchInfos] = useState([] as SketchInfo[]);

  useEffect(() => {
    querySketchInfos({ uid, sort, tags, mySketch })
      .then((sketches) => {
        setSketchInfos(sketches);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [uid, sort, tags, mySketch]);

  const onDeleteSketch = async (sketchId: string) => {
    if (!uid) return;

    try {
      if (window.confirm(`スケッチを削除しますか？`) === false) return;
      await deleteSketch(uid, sketchId);
      setSketchInfos(
        sketchInfos.filter((sketch) => sketch.sketchId !== sketchId)
      );
    } catch (e) {
      window.alert(`エラーが発生しました。${e}`);
    }
  };

  return (
    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 p-4">
      {sketchInfos.map((sketchInfo) => (
        <SketchItem
          sketchInfo={sketchInfo}
          key={sketchInfo.sketchId}
          mySketch={mySketch}
          onDeleteSketch={onDeleteSketch}
        />
      ))}
    </div>
  );
};
