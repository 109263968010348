type Props = {
  messages: string;
};

export function Console({ messages }: Props) {
  return (
    <textarea
      className="form-control  h-100 border-0"
      value={messages}
      readOnly={true}
    ></textarea>
  );
}
