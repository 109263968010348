import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";

// https://qr-code-styling.com/
const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  type: "svg",
  data: "",
  // image: "/logo192.png",
  qrOptions: {
    errorCorrectionLevel: "Q",
  },
  // dotsOptions: {
  //   type: "dots",
  //   color: "#000000",
  // },
  // backgroundOptions: {
  //   color: "#ffffff",
  // },
  // cornersSquareOptions: {
  //   type: "dot",
  //   color: "#000000",
  // },
  imageOptions: {
    crossOrigin: "anonymous",
    imageSize: 0.4,
    margin: 0,
  },
});

export type Props = {
  url: string;
  size?: number;
};

export function Qrode({ url, size = 300 }: Props) {
  const qrimg = useRef(null);
  qrCode._options.width = size;
  qrCode._options.height = size;

  useEffect(() => {
    if (qrimg.current != null) {
      qrCode.append(qrimg.current);
    }
  }, [size]);

  useEffect(() => {
    qrCode.update({ data: url });
  }, [url, size]);

  return (
    <>
      <div ref={qrimg} />
    </>
  );
}
