import { LocalSketch } from "../domain/sketch";

// ローカルストレージにスケッチを一時保存
export const saveCode = (sketch: LocalSketch) => {
  localStorage.setItem("draftSketch", JSON.stringify(sketch));
};

// ローカルストレージに保存されたスケッチを取得
export const loadCode = (): LocalSketch | undefined => {
  const draftSketch = localStorage.getItem("draftSketch");
  if (draftSketch === null) return undefined;
  return JSON.parse(draftSketch);
};

// ローカルストレージに保存されたスケッチを削除
export const deleteCode = () => {
  localStorage.removeItem("draftSketch");
};

// ローカルストレージにスケッチが保存されているかチェック
export const hasCode = (): boolean => {
  return localStorage.getItem("draftSketch") !== null;
};

// 保存するためにログインするためのフラグを立てる
export const setSigningInToSave = () => {
  localStorage.setItem("signingInToSave", "true");
};

// 保存するためにログインするためのフラグが立っているかチェック
export const isSigningInToSave = () => {
  return localStorage.getItem("signingInToSave") === "true";
};

// 保存するためにログインするためのフラグをクリアする
export const clearSigningInToSave = () => {
  localStorage.removeItem("signingInToSave");
};

export const getLastGetReleaseNote = (): Date => {
  const date = localStorage.getItem("lastGetReleaseNote");
  return date ? new Date(date) : new Date();
};

export const setLastGetReleaseNote = () => {
  const date = new Date();
  localStorage.setItem("lastGetReleaseNote", date.toISOString());
};
