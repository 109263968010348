import React, { useState, useEffect, useRef } from "react";

// state
import { useRecoilValue } from "recoil";
import { tagsState, tagColorsState } from "../states/tagsState";

import "@yaireo/dragsort/dist/dragsort.css";

import Tagify from "@yaireo/tagify";
import Tags from "@yaireo/tagify/dist/react.tagify";

/////////////////////////////////////////////////

//////////////////////////////////////////////////
// Tagify settings object

type Props = {
  id?: string;
  defaultValue?: string[];
  value?: string[];
  allowNewTags?: boolean;
  onChange: (tags: string[]) => void;
};

export const TagSelector = ({
  id,
  defaultValue,
  value,
  allowNewTags = true,
  onChange,
}: Props) => {
  const tagifyRef = useRef<Tagify>();

  // just a name I made up for allowing dynamic changes for tagify settings on this component
  const [tagifyProps, setTagifyProps] = useState({});
  const tagColors = useRecoilValue(tagColorsState);
  const tags = useRecoilValue(tagsState);

  // on component mount
  useEffect(() => {
    (async () => {
      setTagifyProps((lastProps) => ({
        ...lastProps,
        whitelist: tags,
        loading: false,
      }));
    })();
  }, []);

  const transformTag = (tagData: any) => {
    const color = tagColors?.[tagData.value] ?? "#999999";
    tagData.color = "#ff0000";
    tagData.style = `--tag-bg: ${color};`;
    tagData.style += `--tag-hover: ${color};`;
    console.log("transform tag", tagData);
  };

  const baseTagifySettings = {
    blacklist: [],
    placeholder: "➕",
    defaultValue: "",
    transformTag,
    dropdown: {
      maxItems: 20, // <- mixumum allowed rendered suggestions
      classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // always show suggestions dropdown
    },
  };

  // merged tagify settings (static & dynamic)
  const settings = {
    ...baseTagifySettings,
    enforceWhitelist: !allowNewTags,
  };

  // access Tagify internal methods example:
  // const clearAll = () => {
  //   tagifyRef1.current && tagifyRef1.current.removeAllTags();
  // };

  return (
    <>
      {/* <button className="btn btn-primary clearAllBtn" onClick={clearAll}>
        Clear All
      </button> */}
      {typeof tagColors !== "undefined" && (
        <Tags
          className="form-control"
          tagifyRef={tagifyRef}
          settings={settings}
          value={value}
          defaultValue={defaultValue}
          autoFocus={true}
          {...tagifyProps}
          onChange={(e) => {
            const val = (e.detail.value ? JSON.parse(e.detail.value) : []) as {
              value: string;
            }[];
            onChange(val.map((tag) => tag.value));
          }}
        />
      )}
    </>
  );
};
