import { useEffect, useState } from "react";
import { javascript } from "@codemirror/lang-javascript";
import CodeMirror from "@uiw/react-codemirror";
import { options } from "../codemirror/autocompletion";

// components
import { Modal } from "./Modal";

// hooks
import { useBootStrapModal } from "../hooks/useModal";
import { useCodeAssist } from "../hooks/useCodeAssist";

type PropType = {
  id: string;
  onCloseReq?: () => void;
  onApply: (generatedCode: string) => void;
};

type GenerateMode = "generate" | "modify";

const option = [
  { label: "新規コード作成", value: "generate" },
  { label: "コード修正", value: "modify" },
];

export const AutoCodeModal = ({ id, onCloseReq, onApply }: PropType) => {
  const [openModal, closeModal] = useBootStrapModal(id, onCloseReq);
  useEffect(() => {
    openModal();
  }, []);

  const [isGenerated, setIsGenerated] = useState<boolean>(false);

  const [prompt, setPrompt] = useState<string>("");

  const [mode, setMode] = useState<GenerateMode>("generate");

  const start = async () => {
    await submitPrompt(prompt);
    setIsGenerated(true);
  };

  const apply = () => {
    onApply(generatedCode);
    closeModal();
  };

  // 生成コード
  const [generatedCode, setGeneratedCode] = useState<string>("");

  const onUpdateCode = (code: string) => setGeneratedCode(code);

  const { submitPrompt, generating } = useCodeAssist(onUpdateCode);

  const footerButtons = isGenerated ? (
    // コード生成済みの場合
    <>
      <button type="button" className="btn btn-secondary" onClick={closeModal}>
        反映せずに閉じる
      </button>
      <button type="button" className="btn btn-primary" onClick={apply}>
        生成されたコードを反映
      </button>
    </>
  ) : (
    // コード未生成の場合
    <>
      <button type="button" className="btn btn-secondary" onClick={closeModal}>
        閉じる
      </button>
      <button type="button" className="btn btn-primary" onClick={start}>
        開始
      </button>
    </>
  );

  return (
    <Modal title="プロンプトを入力" id={id} footer={footerButtons} large>
      {option.map((item) => (
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id={item.value}
            value={item.value}
            checked={mode === item.value}
            onClick={() => setMode(item.value as GenerateMode)}
          />
          <label className="form-check-label" htmlFor={item.value}>
            {item.label}
          </label>
        </div>
      ))}
      <div className="codeeditor-container">
        <CodeMirror
          id="codemirror"
          value={generatedCode}
          extensions={[javascript({ jsx: true })]}
          readOnly
          height="300px"
        />
        {generating && (
          <div className="overlay d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary me-3" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div>生成中...</div>
          </div>
        )}
      </div>
      <textarea
        name="description"
        className="form-control"
        defaultValue={prompt}
        rows={7}
        onChange={(e) => setPrompt(e.target.value)}
      />
    </Modal>
  );
};
