import { PLUGINS } from "./setting";
import { APP_SETTINGS } from "./setting";

export const getIframeHTML = (
  code: string,
  plugins?: string[],
  uid?: string,
  sketchId?: string
) => {
  const blob = new Blob([code], { type: "text/plain" });
  const codeUrl = URL.createObjectURL(blob);

  const base =
    uid && sketchId
      ? `<base href="${APP_SETTINGS.storageUrl}Users/${uid}/SketchFiles/${sketchId}/Assets/"`
      : "";

  return `<html>
  <head>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.6.0/p5.js"></script>
    ${
      plugins
        ?.map((plugin) => `<script src="${PLUGINS[plugin]}"></script>`)
        .join("") ?? ""
    }
    <script>
      window.addEventListener('error', (e) => {
        const msg = JSON.stringify({
          type: "error",
          load: {
            message: e.message,
            lineno: e.lineno,
            colno: e.colno,
          }
        });

        window.parent.postMessage(msg, "*");
      });
      console.log = function (message) {
        const msg = JSON.stringify({
          type: "log",
          load: {
            message: message
          }
        });
        window.parent.postMessage(msg, "*");
      };
    </script>
    ${base}
  </head>
    <script src="${codeUrl}"></script>
  <body style="margin:0;padding:0">
  </body>
</html>
  `;
};
