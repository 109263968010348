import { useState, useEffect } from "react";
import { PLUGINS } from "../setting";

// hooks
import { useBootStrapModal } from "../hooks/useModal";

// domain
import { LocalSketch } from "../domain/sketch";
import { Tags } from "../domain/tags";

// component
import { TagSelector } from "../components/TagSelector";
import { Modal } from "./Modal";

// state
import { useRecoilState } from "recoil";
import { authState } from "../states/authState";
import { userState } from "../states/userState";

import { activeEditor } from "../components/editorPreviewSwitcher";

type PropTypes = {
  id: string;
  onCloseReq?: () => void;
  defaultValue: LocalSketch;
  onChange: (sketch: Partial<LocalSketch>) => void;
};

export const SketchSettingsModal = ({
  id,
  onCloseReq,
  defaultValue: { status, desc, tags, plugins },
  onChange,
}: PropTypes) => {
  const [openModal, closeModal] = useBootStrapModal(id, onCloseReq);
  useEffect(() => {
    openModal();
  }, []);

  const [user, setUser] = useRecoilState(userState);
  const [auth] = useRecoilState(authState);
  const [updatedUserName, setUpdatedUserName] = useState<string>(user.uname);
  const [isUserNameEditing, setIsUserNameEditing] = useState(false);
  const [warningToPublish, setWarningToPublish] = useState(false);

  const sketch: Partial<LocalSketch> = {
    status,
    desc,
    tags,
    plugins,
  };

  const onChangeStatus = (checked: boolean) => {
    if (user.uname === "") {
      setWarningToPublish(true);
    } else {
      onChange({ status: checked ? "public" : "draft" });
    }
  };

  const onChangeARPlugin = (checked: boolean) => {
    if (checked) {
      onChange({
        plugins: [...(sketch.plugins ?? []), PLUGINS["p5SimpleAR-0.7.0"]],
      });
    } else {
      onChange({ plugins: plugins.filter((p) => p !== "p5SimpleAR-0.7.0") });
    }
    onChange({ plugins: checked ? ["p5SimpleAR-0.7.0"] : [] });
  };

  const onChangeUserName = (uname: string) => {
    setUpdatedUserName(uname);
    setWarningToPublish(false);
  };

  const onClickUserNameButton = () => {
    if (isUserNameEditing) {
      if (updatedUserName !== user.uname) {
        console.log("update user name");
        setUser({ ...user, uname: updatedUserName });
      }
    }
    setIsUserNameEditing(!isUserNameEditing);
  };

  const onChangeDesc = (desc: string) => {
    onChange({ desc });
  };

  const onChangeTags = (tags: Tags) => {
    onChange({ tags });
  };

  return (
    <Modal
      title="設定"
      id={id}
      footer={
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
        >
          閉じる
        </button>
      }
    >
      <div className="d-flex flex-column  p-0" onClick={activeEditor}>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="public"
            checked={sketch.status === "public"}
            onChange={(e) => onChangeStatus(e.currentTarget.checked)}
          />
          <label className="form-check-label" htmlFor="public">
            公開する{" "}
            {warningToPublish && (
              <span
                className="warning
          "
              >
                作者の名前を設定してください。
              </span>
            )}
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="plugin"
            defaultChecked={
              sketch.plugins?.includes("p5SimpleAR-0.7.0") === true
            }
            onChange={(e) => onChangeARPlugin(e.currentTarget.checked)}
          />
          <label className="form-check-label" htmlFor="public">
            ARプラグイン
          </label>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="form-label" htmlFor="uname">
              作者の名前(全てのスケッチに反映されます。):
            </label>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                name="uname"
                id="uname"
                defaultValue={user.uname}
                onChange={(e) => onChangeUserName(e.target.value)}
                disabled={!isUserNameEditing}
                readOnly={!isUserNameEditing}
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                id="unamebutton"
                onClick={onClickUserNameButton}
              >
                {isUserNameEditing ? "保存" : "編集"}
              </button>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label className="form-label" htmlFor="description">
              説明:
            </label>
            <textarea
              name="description"
              className="form-control"
              defaultValue={sketch.desc}
              rows={7}
              onChange={(e) => onChangeDesc(e.target.value)}
            />
          </div>
          <div className="col-12 mb-3">
            <label className="form-label" htmlFor="description">
              タグ:
            </label>
            <TagSelector
              defaultValue={sketch.tags}
              onChange={(tags) => onChangeTags(tags)}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
