import {
  getFirestore,
  doc,
  collection,
  collectionGroup,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  FirestoreDataConverter,
  FieldValue,
  serverTimestamp,
  Timestamp,
  query,
  where,
  or,
  and,
  orderBy,
  QueryFieldFilterConstraint,
  QueryOrderByConstraint,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getBlob,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";

import { NewSketch, UpdatedSketch, SketchInfo } from "../domain/sketch";

export type QueryType = {
  uid?: string;
  sort?: SortTypes;
  tags?: string[];
  mySketch?: boolean;
};

// firebase document
export type SketchDoc = Omit<SketchInfo, "files"> & {
  createdAt: Date | FieldValue;
  updatedAt: Date | FieldValue;
};

export type SortTypes = undefined | "latest" | "oldest";

const converter: FirestoreDataConverter<SketchDoc> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot, options) {
    // convet { [tag]: true } to string[]
    const data = snapshot.data(options);
    return {
      ...data,
      createdAt: (data.createdAt as Timestamp)?.toDate(),
      updatedAt: (data.updatedAt as Timestamp)?.toDate(),
    } as SketchDoc;
  },
};

export { converter as codeConverter };

export const storeNewSketch = async (sketch: NewSketch): Promise<string> => {
  const { files, ...sketchDoc } = {
    ...sketch,
    sketchId: "", // dummy
    shortId: "", // dummy
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  };
  const uid = sketch.uid;

  // store code infomation to firestore
  const db = getFirestore();

  const sketchId = (
    await addDoc(
      collection(db, `Users/${uid}/SketchInfos`).withConverter(converter),
      sketchDoc
    )
  ).id;
  await updateDoc(doc(db, `Users/${uid}/SketchInfos/${sketchId}`), {
    sketchId,
  });

  // store code in storage
  const storage = getStorage();
  files.forEach((file) => {
    const codeRef = ref(
      storage,
      `Users/${uid}/SketchFiles/${sketchId}/${file.filename}`
    );
    uploadBytes(codeRef, file.contents);
  });

  return sketchId;
};

export const updateSketch = async (
  uid: string,
  sketchId: string,
  sketch: UpdatedSketch
) => {
  const { files, ...sketchDoc } = {
    ...sketch,
    updatedAt: serverTimestamp(),
  };

  // store code infomation to firestore
  const db = getFirestore();

  // update doc and merge
  await updateDoc(
    doc(db, `Users/${uid}/SketchInfos/${sketchId}`).withConverter(converter),
    sketchDoc
  );

  // store code in storage
  const storage = getStorage();
  files.forEach((file) => {
    const codeRef = ref(
      storage,
      `Users/${uid}/SketchFiles/${sketchId}/${file.filename}`
    );
    uploadBytes(codeRef, file.contents);
  });
};

// uidとsketchIdから取得
export const getSketchInfo = async (
  uid: string,
  sketchId: string
): Promise<SketchInfo> => {
  const db = getFirestore();

  const snapshot = await getDoc(
    doc(db, "Users", uid, "SketchInfos", sketchId).withConverter(converter)
  );

  if (!snapshot.exists()) throw new Error("No such sketch");

  const data = snapshot.data();

  const sketchInfo: SketchInfo = {
    ...data,
    sketchId: snapshot.id,
    createdAt: data.createdAt as Date,
    updatedAt: data.updatedAt as Date,
  };

  return sketchInfo;
};

// uidがわからないスケッチIDのみでクエリする。
// authUidはログインしているユーザーのUID
export const getSketchInfoFromSketchId = async (
  sketchId: string,
  authUid: string
): Promise<SketchInfo> => {
  const db = getFirestore();

  // FirestoreのCollectionGroup SketchInfosからidが一致するものを取得
  const fieldName = sketchId.length > 10 ? "sketchId" : "shortId";
  const condition = authUid
    ? and(
        where(fieldName, "==", sketchId),
        or(where("uid", "==", authUid), where("status", "==", "public"))
      )
    : and(where(fieldName, "==", sketchId), where("status", "==", "public"));

  const snapshot = await getDocs(
    query(
      collectionGroup(db, "SketchInfos").withConverter(converter),
      condition
    )
  );

  if (snapshot.size !== 1) throw new Error("No such sketch");

  const data = snapshot.docs[0].data();

  const sketchInfo: SketchInfo = {
    ...data,
    sketchId: snapshot.docs[0].id,
    createdAt: data.createdAt as Date,
    updatedAt: data.updatedAt as Date,
  };

  return sketchInfo;
};

// スケッチをクエリ
export const querySketchInfos = async (
  q?: QueryType
): Promise<SketchInfo[]> => {
  const db = getFirestore();

  // uidが指定されているときはパスを指定して、そうでないときはCollectionGroupを指定する
  const colRef = q?.uid
    ? collection(db, `Users/${q.uid}/SketchInfos`).withConverter(converter)
    : collectionGroup(db, "SketchInfos").withConverter(converter);

  const queryArray: (QueryFieldFilterConstraint | QueryOrderByConstraint)[] =
    [];
  if (q?.sort === "latest") {
    queryArray.push(orderBy("updatedAt", "desc"));
  } else if (q?.sort === "oldest") {
    queryArray.push(orderBy("updatedAt", "asc"));
  }
  if (q?.tags && q.tags.length !== 0) {
    queryArray.push(where("tags", "array-contains-any", q.tags));
  }
  if (q?.mySketch) {
    queryArray.push(where("uid", "==", q?.uid));
  } else {
    queryArray.push(where("status", "==", "public"));
  }

  const snapshot = await getDocs(query(colRef, ...queryArray));
  return snapshot.docs.map((sketchDoc) => {
    const data = sketchDoc.data();
    const sketchInfo: SketchInfo = {
      ...data,
      tags: data.tags,
      sketchId: sketchDoc.id,
      createdAt: data.createdAt as Date,
      updatedAt: data.updatedAt as Date,
    };

    return sketchInfo;
  });
};

// export const getSketchFiles = async (
//   uid: string,
//   sketchId: string
// ): Promise<SketchFile[]> => {
//   const storage = getStorage();
//   const sketch = ref(storage, `Users/${uid}/SketchFiles/${sketchId}`);
//   const files = await listAll(sketch);
//   const sketchFiles = await Promise.all(
//     files.items.map(async (file): Promise<SketchFile> => {
//       const blob = await getBlob(file);
//       return { filename: file.name, contents: blob };
//     })
//   );
//   return sketchFiles;
// };

export const getSketchMainJs = async (
  uid: string,
  sketchId: string
): Promise<string> => {
  const storage = getStorage();
  const sketchMainJsRef = ref(
    storage,
    `Users/${uid}/SketchFiles/${sketchId}/main.js`
  );
  const blob = await getBlob(sketchMainJsRef);
  return blob.text();
};

// export const getFileInfos = async (
//   uid: string,
//   sketchId: string
// ): Promise<FileInfos> => {
//   const storage = getStorage();
//   const sketch = ref(storage, `Users/${uid}/SketchFiles/${sketchId}`);
//   const files = await listAll(sketch);
//   const fileInfos = await Promise.all(
//     files.items
//       .filter((f) => f.name !== "main.js")
//       .map(async (file) => {
//         const fileRef = ref(storage, file.fullPath);
//         const metadata = await getMetadata(fileRef);

//         return {
//           filename: file.name,
//           size: metadata.size,
//           createdAt: new Date(metadata.timeCreated),
//           updatedAt: new Date(metadata.updated),
//         };
//       })
//   );
//   return fileInfos;
// };

export const deleteFile = async (
  uid: string,
  sketchId: string,
  filename: string
): Promise<void> => {
  const storage = getStorage();

  await deleteObject(
    ref(storage, `Users/${uid}/SketchFiles/${sketchId}/${filename}`)
  );
};

export const deleteSketch = async (uid: string, sketchId: string) => {
  const db = getFirestore();
  const storage = getStorage();

  deleteDoc(doc(db, `Users/${uid}/SketchInfos/${sketchId}`));

  const files = await listAll(
    ref(storage, `Users/${uid}/SketchFiles/${sketchId}/`)
  );

  await Promise.all(
    files.items.map((file) => {
      return deleteObject(ref(storage, file.fullPath));
    })
  );
};

// export const getSkechThumnail = async (
//   uid: string,
//   sketchId: string
// ): Promise<string> => {
//   try {
//     const storageRef = ref(
//       getStorage(),
//       `/Users/${uid}/SketchInfos/${sketchId}/thumbnail.png`
//     );
//     const blob = await getBlob(storageRef);
//     return URL.createObjectURL(blob);
//   } catch (e) {
//     return "/noimage.png";
//   }
// };

export const getThumbnailUrl = async (
  uid: string,
  sketchId: string
): Promise<string> => {
  try {
    const storage = getStorage();
    const sketchThumbnailRef = ref(
      storage,
      `Users/${uid}/SketchFiles/${sketchId}/thumbnail.png`
    );
    return await getDownloadURL(sketchThumbnailRef);
  } catch (e) {
    return "/noimage.png";
  }
};

// export const getThumbnailUrl = (uid: string, sketchId: string): string => {
//   // return getDownloadURL(
//   //   ref(getStorage(), `Users/${uid}/SketchFiles/${sketchId}/thumbnail.png`)
//   // );
//   return (
//     APP_SETTINGS.storageUrl +
//     `/Users/${uid}/SketchFiles/${sketchId}/thumbnail.png`
//   );
// };
