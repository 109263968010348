export const defaultUser = {
  uname: "",
  introduction: "",
  hasProfileImg: false,
};

export type User = typeof defaultUser;

export type UpdatedUser = Partial<User>;
// hasPrifileImgはFirebase Functions のみから更新する。
