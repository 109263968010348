import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

// infrastructure
import { signout } from "../infrastructure/auth";

// component
import { SigninModal } from "../modals/SigninModal";
import { Sketches } from "../components/Sketches";
import { ProfileIcon } from "./ProfileIcon";
import { ToolBarButton } from "../components/ToolBarButton";

import {
  NavItem,
  NavItemDropdown,
  DropDownItem,
  Divider,
} from "./Sidebar/SidebarItems";
import { ProfileModal } from "../modals/ProfileModal";

// state
import { useRecoilValue } from "recoil";
import { authState } from "../states/authState";
import { userState } from "../states/userState";

type PropsType = {
  left?: React.ReactNode;
  center?: React.ReactNode;
  right?: React.ReactNode;
};

export const Navbar = ({ left, center, right }: PropsType) => {
  const [isSigninModalOpened, setSigninModalOpened] = useState(false);
  const [isProfileModalOpened, setProfileModalOpened] = useState(false);

  const { uid, isSignin } = useRecoilValue(authState);
  const user = useRecoilValue(userState);

  useEffect(() => {
    if (isSignin === true) {
      setSigninModalOpened(false);
    }
  }, [isSignin]);

  const signin = () => {
    setSigninModalOpened(true);
  };

  const onSignout = () => {
    signout();
    navigate("/");
  };
  const navigate = useNavigate();

  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src="/images/logo.png" height={54} alt="egocoro" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navibartoggle"
            aria-controls="navibartoggle"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navibartoggle">
            <div className="d-flex flex-fill justify-content-between">
              <div>{left}</div>
              <div>{center}</div>
              <div>{right}</div>
            </div>
            <ul className="navbar-nav me-3">
              <li className="nav-item">
                <Link className="nav-link" to={"/gallery"}>
                  探す
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/editor"}>
                  作る
                </Link>
              </li>
              {isSignin && (
                <li className="nav-item">
                  <Link className="nav-link" to={`/user/${uid}`}>
                    私のスケッチ
                  </Link>
                </li>
              )}
            </ul>

            {isSignin ? (
              <NavItemDropdown
                title={
                  <>
                    <ProfileIcon uid={uid} hasProfileImg={user.hasProfileImg} />

                    <div className="d-inline-block mx-2 text-wrap">
                      {user.uname === "" ? "Unnamed" : user.uname}
                    </div>
                  </>
                }
                id="accountMenu"
              >
                <DropDownItem
                  onClick={() => setProfileModalOpened(true)}
                  icon="person-square"
                >
                  プロフィール設定
                </DropDownItem>
                <Divider />
                <DropDownItem onClick={onSignout} icon="door-closed">
                  サインアウト
                </DropDownItem>
              </NavItemDropdown>
            ) : (
              <>
                <ToolBarButton onClick={signin}>サインイン</ToolBarButton>
                <ToolBarButton onClick={signin} strong>
                  サインアップ
                </ToolBarButton>
              </>
            )}
          </div>
        </div>
      </nav>
      {isSigninModalOpened && (
        <SigninModal
          id="modal-signin-sidebar"
          onCloseReq={() => {
            setSigninModalOpened(false);
            navigate("/gallery");
          }}
        />
      )}
      {isProfileModalOpened && (
        <ProfileModal
          id="modal-profile"
          onCloseReq={() => setProfileModalOpened(false)}
        />
      )}
    </>
  );
};
