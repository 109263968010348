import { APP_SETTINGS } from "../setting";

export const initialLocalSketch = {
  status: "draft" as "public" | "draft" | "trashed" | "deleted",
  title: APP_SETTINGS.defaultTitle,
  desc: "",
  tags: [] as string[],
  plugins: [] as string[],
  hasThumbnail: false,
  code: APP_SETTINGS.defaultCode,
  sketchId: undefined as string | undefined,
  shortId: undefined as string | undefined,
  thumbnail: undefined as Blob | undefined,
};

export type LocalSketch = typeof initialLocalSketch;

export type SketchFile = {
  filename: string;
  contents: Blob;
};

export type UpdatedSketch = Omit<
  LocalSketch,
  "code" | "sketchId" | "shortId" | "thumbnail"
> & {
  files: SketchFile[];
};

export type NewSketch = UpdatedSketch & {
  uid: string;
  uname: string;
  hasProfileImg: boolean;
};

export type SketchInfo = Omit<NewSketch, "files"> & {
  sketchId: string;
  shortId: string;
  createdAt: Date;
  updatedAt: Date;
};
