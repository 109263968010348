import { getAuth, signOut, deleteUser as _deleteUser } from "firebase/auth";

export const signout = async () => {
  await signOut(getAuth());
};

export const deleteUser = async (): Promise<void> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw Error("no user");

  await _deleteUser(user);
};
