//https://codemirror.net/docs/ref/#autocomplete
// "utility/extract.js" から生成したsymbol.jsonをインポートする

//  * @property {Number} mouseX
//  * @readOnly
//  *
//  * @example

//  * @method background
//  * @param {p5.Color} color  any value created by the <a href="#/p5/color">color()</a> function
//  * @chainable
//  *
//  * @example

import symbol from "./symbol.json";
export const options = symbol;
