import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// hooks
import { useBootStrapModal } from "../hooks/useModal";

// components
import { Modal } from "./Modal";

import { useDropzone } from "react-dropzone";

// infrastructure
import {
  uploadUserProfileImage,
  getProfileImage,
} from "../infrastructure/userRepository";

import { deleteUser } from "../infrastructure/auth";

// state
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "../states/authState";
import { userState } from "../states/userState";

type PropsType = {
  id: string;
  onCloseReq?: () => void;
};

export const ProfileModal = ({ id, onCloseReq }: PropsType) => {
  const [openModal, closeModal] = useBootStrapModal(id, onCloseReq);

  const navigate = useNavigate();
  const auth = useRecoilValue(authState);

  const [user, setUser] = useRecoilState(userState);
  const [updatedUser, setUpdatedUser] = useState(user);

  const [upladedFileUrl, setUploadedfileUrl] = useState<string>("");
  const [upladedFile, setUploadedfile] = useState<ArrayBuffer | undefined>(
    undefined
  );
  const [uploadFileName, setUploadFileName] = useState<string>("");
  const [uploadFileScale, setUploadFileScale] = useState<number>(1.0);

  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    openModal();
  }, []);

  useEffect(() => {
    setUpdatedUser(user);
    if (!auth.uid || !user.hasProfileImg) return;
    (async () => {
      const profileImage = await getProfileImage(auth.uid);
      setUploadedfileUrl(profileImage);
    })();
  }, [auth.uid, user]);

  const onDrop = (files: File[]) => {
    files.forEach((file) => {
      if (file.size > 2000000) console.log("ファイルサイズが大きすぎます。");
      setUploadFileName(file.name);
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (event) => {
        const result = event.target?.result;
        if (typeof result === "string") {
          setUploadedfileUrl(result);
          reader.readAsArrayBuffer(file);
        }
        if (typeof result === "object" && result) {
          setUploadedfile(result);
          setUpdatedUser({ ...updatedUser, hasProfileImg: true });
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    multiple: false,
    onDrop,
  });

  const save = async () => {
    console.log("save");
    setProcessing(true);

    try {
      if (upladedFile)
        await uploadUserProfileImage(
          auth.uid,
          uploadFileName,
          uploadFileScale,
          new Blob([upladedFile], { type: "application/octet-stream" })
        );
      setUser(updatedUser);
      setTimeout(() => {
        closeModal();
        setProcessing(false);
      }, 1000);
    } catch (e) {
      console.error(e);
      closeModal();
      setProcessing(false);
    }
  };

  const profileImg = useRef<HTMLImageElement>(null);

  const changeImageSize = (scale: number) => {
    setUploadFileScale(scale);
    if (!profileImg) return;
    profileImg.current?.style.setProperty("transform", `scale(${scale})`);
  };

  const deleteAccount = async () => {
    try {
      const emailToConvirm = window.prompt(
        `アカウントを削除しますか？本当に削除する場合には登録されているメールアドレスを入力してください。`
      );
      if (emailToConvirm !== auth.email) return;
      await deleteUser();
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (e) {
      window.alert(`エラーが発生しました。${e}`);
    }
  };

  return (
    <Modal
      title="プロフィール設定"
      id={id}
      footer={
        <>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={closeModal}
          >
            閉じる
          </button>

          <button type="button" className="btn btn-primary" onClick={save}>
            <span
              className={
                "spinner-border spinner-border-sm me-2" +
                (processing ? "" : " d-none")
              }
              role="status"
              aria-hidden="true"
            ></span>
            保存
          </button>
        </>
      }
      large
    >
      <div className="container" id="profile-container">
        <div className="row">
          <div className="col-auto">
            <div {...getRootProps()} className="col">
              <div className="fileuploader">
                <input {...getInputProps()} />
                <div id="profile-image">
                  <img
                    src={
                      upladedFileUrl
                        ? upladedFileUrl
                        : user && user.hasProfileImg
                        ? upladedFileUrl
                        : "/profile.png"
                    }
                    className="profile-image"
                    ref={profileImg}
                    alt="profile"
                    onClick={open}
                  />
                </div>
                ファイルをここにドラッグ＆ドロップ
                {isDragActive && (
                  <div className="uploading h-100 w-100 d-flex justify-content-center align-items-center">
                    <p>ファイルをドロップするとアップロードできます。</p>
                  </div>
                )}
              </div>
            </div>

            <input
              type="range"
              className="form-range"
              id="profile-image-size"
              min="1"
              max="10"
              step="0.01"
              defaultValue={uploadFileScale}
              onChange={(e) => changeImageSize(Number(e.currentTarget.value))}
              disabled={!upladedFile}
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label className="form-label" htmlFor="email">
              メールアドレス(公開されません):
            </label>
            <input
              id="email"
              type="text"
              className="form-control mb-3"
              name="email"
              defaultValue={auth.email}
              disabled
            />
            <label className="form-label" htmlFor="uname">
              名前:
            </label>
            <input
              id="uname"
              type="text"
              className="form-control mb-3"
              defaultValue={user.uname}
              onChange={(e) =>
                setUpdatedUser({ ...updatedUser, uname: e.currentTarget.value })
              }
            />
            <label className="form-label" htmlFor="introduction">
              自己紹介:
            </label>
            <textarea
              id="introduction"
              name="introduction"
              className="form-control mb-3"
              rows={7}
              defaultValue={user.introduction}
              onChange={(e) =>
                setUpdatedUser({
                  ...updatedUser,
                  introduction: e.currentTarget.value,
                })
              }
            />
            <div className="text-end">
              <button
                className="btn btn-outline-danger"
                onClick={deleteAccount}
              >
                アカウントを削除する
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
