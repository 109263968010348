import React, { useEffect, useMemo } from "react";

import { getIframeHTML } from "../iframe-html";

const resizeIframe = () => {
  const iframe = document.getElementById("preview-iframe") as HTMLIFrameElement;
  if (!iframe || !iframe.contentWindow) return;
  const innerDoc = iframe.contentDocument || iframe.contentWindow?.document;
  const targetElm = innerDoc.getElementById("defaultCanvas0");
  const width = targetElm ? targetElm.offsetWidth : 640;
  const height = targetElm ? targetElm.offsetHeight : 480;
  iframe.width = width.toString();
  iframe.height = height.toString();
};

type Props = {
  uid?: string;
  sketchId?: string;
  code: string;
  plugins: string[];
  play: boolean;
};

export function Preview({ uid, sketchId, code, plugins, play }: Props) {
  useEffect(() => {
    [100, 200, 500, 1000, 2000, 5000, 10000].forEach((delay) => {
      setTimeout(() => {
        resizeIframe();
      }, delay);
    });
  }, [code, plugins, play]);

  const srcUrl = useMemo(() => {
    const html = getIframeHTML(code, plugins, uid, sketchId);
    const blob = new Blob([html], { type: "text/html" });
    return window.URL.createObjectURL(blob);
  }, [code, plugins]);

  return (
    <>
      {play && (
        <iframe
          id="preview-iframe"
          title="playground"
          width={600}
          height={600}
          src={srcUrl}
          className="align-self-center shadow"
        ></iframe>
      )}
    </>
  );
}
