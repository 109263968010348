import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  deleteObject,
  getMetadata,
} from "firebase/storage";

export type FileInfos = {
  filename: string;
  size: number;
  createdAt: Date;
  updatedAt: Date;
}[];

export const uploadAssetFile = async (
  uid: string,
  sketchId: string,
  filename: string,
  contents: ArrayBuffer
): Promise<void> => {
  const storage = getStorage();

  const fileRef = ref(
    storage,
    `Users/${uid}/SketchFiles/${sketchId}/Assets/${filename}`
  );

  await uploadBytes(fileRef, contents);
};

export const listAssetFile = async (
  uid: string,
  sketchId: string
): Promise<FileInfos> => {
  const storage = getStorage();
  const sketch = ref(storage, `Users/${uid}/SketchFiles/${sketchId}/Assets/`);
  const files = await listAll(sketch);
  const fileInfos = await Promise.all(
    files.items.map(async (file) => {
      const fileRef = ref(storage, file.fullPath);
      const metadata = await getMetadata(fileRef);

      return {
        filename: file.name,
        size: metadata.size,
        createdAt: new Date(metadata.timeCreated),
        updatedAt: new Date(metadata.updated),
      };
    })
  );
  return fileInfos;
};

// cloud storageから直接URLでアクセスできるため、使用しない
// export const getAssetFileUrls = async (
//   uid: string,
//   sketchId: string
// ): Promise<FileUrls> => {
//   const storage = getStorage();
//   const files = await listAll(
//     ref(storage, `Users/${uid}/SketchFiles/${sketchId}/Assets`)
//   );
//   return await Promise.all(
//     files.items.map(async (file) => {
//       const blob = await getBlob(file);
//       const url = URL.createObjectURL(blob);
//       return {
//         filename: file.name,
//         url: await getDownloadURL(ref(storage, file.fullPath)),
//       };
//     })
//   );
// };

export const deleteAssetFile = async (
  uid: string,
  sketchId: string,
  filename: string
): Promise<void> => {
  const storage = getStorage();

  await deleteObject(
    ref(storage, `Users/${uid}/SketchFiles/${sketchId}/Assets/${filename}`)
  );
};
