import React from "react";

export const Main = (props: {
  navbar?: React.ReactNode;
  contents: React.ReactNode;
}) => {
  return (
    <>
      {props.navbar}
      <div className="container-fluid px-0" id="main-container">
        {props.contents}
      </div>
    </>
  );
};
