export const useBootStrapDropdown = (id: string) => {
  // const element = document.getElementById(id);
  const bootstrap = require("bootstrap");

  const toggleDropdown = () => {
    // const dropdownElementList = [].slice.call(
    //   document.querySelectorAll(`#${id}`)
    // );
    // const dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
    //   return new bootstrap.Dropdown(dropdownToggleEl);
    // });

    // if (!dropdownList) return;

    // dropdownList.forEach((dropdown) => {
    //   dropdown.toggle();
    // });
    const element = document.getElementById(id);
    if (!element) return;

    const modal = new bootstrap.Dropdown(element, { autoClose: "outside" });
    modal.toggle();
  };

  return [toggleDropdown];
};
