import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

// component
import { SigninModal } from "../modals/SigninModal";
import { Sketches } from "../components/Sketches";
import { Navbar } from "../components/Navbar";

// state
import { useRecoilValue } from "recoil";
import { authState } from "../states/authState";

// template
import { Main } from "../templates/Main";

export const Landing = () => {
  const [isSigninModalOpened, setSigninModalOpened] = useState(false);

  const { uid, isSignin } = useRecoilValue(authState);

  useEffect(() => {
    if (isSignin === true) {
      setSigninModalOpened(false);
    }
  }, [isSignin]);

  const signin = () => {
    setSigninModalOpened(true);
  };

  const navigate = useNavigate();

  const contents = (
    <>
      <header className="container-fluid p-0">
        <img src="/images/head.jpg" alt="head" className="img-fluid" />
      </header>
      <div className="container">
        <div className="row">
          <div className="col" id="copy">
            <span className="display-4">“体験型教材” で新たな学びを創造</span>
          </div>
        </div>
      </div>

      <div className="container">
        <h2>デジタル教材が簡単に作れる</h2>
        <div className="col mb-5">
          <span style={{ fontWeight: 700 }}>egocoro</span>
          を使えばブラウザで触って学べるデジタル教材を簡単に作ることができます。
          クリエイターのためのプログラミングツールであるp5.jsを使って作成します。
          AIがアシストしてくれるので、初心者でもすぐに使い始めることができます。
        </div>
        <h2>すぐに共有できる</h2>
        <div className="col mb-5">
          <span style={{ fontWeight: 700 }}>egocoro</span>
          では作成した教材はQRコードや6桁の数字を使って共有して、すぐにタブレットやノートPCから学べます。ブラウザで動くのでアプリのインストールも不要。授業時間を有効活用できます。
        </div>

        <h2>最近作成されたスケッチ</h2>
        <div className="row">
          <div id="samples" className="col py-3 d-flex justify-content-center">
            <div id="sketches-container" className="container p-0">
              <Sketches sort={"latest"} />
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container pt-5 pb-5">
          <div className="row justify-content-md-center  mb-3">
            <div className="col text-left">
              <p>
                <a href="https://eteq.co.jp/" role="button">
                  株式会社ETeq
                </a>
              </p>
              <p>
                〒108-0074
                <br />
                東京都港区高輪四丁目23番6号
                <br />
                ハイホーム高輪708
              </p>
            </div>

            <div className="col-4">
              <p>利用規約</p>
              <p>プライバシーポリシー</p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col text-center">&copy; 株式会社ETeq, 2023</div>
          </div>
        </div>
      </footer>
      {isSigninModalOpened && (
        <SigninModal
          id="modal-signin-sidebar"
          onCloseReq={() => {
            setSigninModalOpened(false);
            navigate("/gallery");
          }}
        />
      )}
    </>
  );

  return <Main navbar={<Navbar />} contents={contents} />;
};
