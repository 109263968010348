import React, { useRef, useState } from "react";

import fullscreenButton from "./fullscreen-sidemenu-button.png";

type Props = {
  children: React.ReactNode;
  sidemenuContent?: React.ReactNode;
  onClose: () => void;
};

export const FullScreen = ({ children, sidemenuContent, onClose }: Props) => {
  const sidemenuButton = useRef<HTMLDivElement>(null);
  const sidemenu = useRef<HTMLDivElement>(null);
  const [isSidemenuOpen, setIsSidemenuOpen] = useState<boolean>(false);

  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | undefined>(
    undefined
  );

  const showSidemenu = () => {
    if (!sidemenu.current) return;

    if (!isSidemenuOpen) {
      sidemenu.current.style.display = "block";
      sidemenu.current.classList.remove("slide-out");
      sidemenu.current.classList.add("slide-in");
      setIsSidemenuOpen(true);
      if (timeoutID) clearTimeout(timeoutID);
    } else {
      sidemenu.current.classList.remove("slide-in");
      sidemenu.current.classList.add("slide-out");
      setIsSidemenuOpen(false);
    }
  };

  const showSidemenuToggleButton = () => {
    if (!sidemenuButton.current) return;

    sidemenuButton.current.classList.remove("slide-out");
    sidemenuButton.current.classList.add("slide-in");
    if (!isSidemenuOpen) {
      if (timeoutID) clearTimeout(timeoutID);
      setTimeoutID(
        setTimeout(() => {
          if (!sidemenuButton.current) return;
          sidemenuButton.current.classList.remove("slide-in");
          sidemenuButton.current.classList.add("slide-out");
        }, 1000)
      );
    }
  };

  return (
    <div
      className="container-fluid h-100 w-100 p-0"
      id="fullscreen-container"
      onMouseMove={() => {
        showSidemenuToggleButton();
      }}
    >
      <div className="row h-100 w-100 m-0">
        <div className="w-100 flex-fill p-0">{children}</div>
      </div>
      {sidemenuContent && (
        <div id="sidemenu" ref={sidemenu} className="slide-start">
          <div
            id="sidemenu-button"
            ref={sidemenuButton}
            className="slide-start"
          >
            <img
              src="/images/fullscreen-sidemenu-button.png"
              alt="button"
              onClick={showSidemenu}
            />
          </div>
          <div id="sidemenu-content" className="w-100 h-100">
            {sidemenuContent}
          </div>
        </div>
      )}
      <button
        id="exit-fullscreen"
        type="button"
        className="btn-close btn-close-white"
        aria-label="Close"
        onClick={onClose}
      ></button>
    </div>
  );
};
