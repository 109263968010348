import { useEffect, useState } from "react";

import { getProfileIcon } from "../infrastructure/userRepository";

type Props = { uid: string; hasProfileImg: boolean };

export const ProfileIcon = ({ uid, hasProfileImg }: Props) => {
  const [url, setUrl] = useState<string>("/profile.png");
  useEffect(() => {
    if (!hasProfileImg) return;
    (async () => {
      const url = await getProfileIcon(uid);
      setUrl(url);
    })();
  });
  return (
    <>
      <div className="profile-icon d-inline-block">
        <img src={url} alt="icon" />
      </div>
    </>
  );
};
