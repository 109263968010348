type Props = {
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  processing?: boolean;
  icon?: string;
  strong?: boolean;
  narrow?: boolean;
};

export const ToolBarButton = ({
  onClick,
  children,
  disabled = false,
  processing = false,
  icon,
  strong = false,
  narrow = false,
}: Props) => {
  return (
    <button
      className={
        "btn btn-sm sidebar-button mx-1 " +
        (strong ? "btn-primary" : "btn-outline-primary")
      }
      onClick={onClick}
      disabled={disabled ?? false}
    >
      {processing && (
        <div
          className="spinner-border spinner-border-sm text-light me-2"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      {icon && <i className={`bi bi-${icon} me-2`}></i>}

      {children}
    </button>
  );
};
