import { ReactNode } from "react";
import { Link } from "react-router-dom";

// hooks
import { useBootStrapDropdown } from "../../hooks/useBootStrapDropdown";

const NavItem = (props: {
  to?: string;
  onClick?: () => void;
  children: ReactNode;
  icon?: string;
}) => {
  return (
    <li className="nav-item">
      {props.to && (
        <Link to={props.to} className="nav-link">
          {props.icon && <i className={`bi bi-${props.icon} me-2`}></i>}
          {props.children}
        </Link>
      )}
      {props.onClick && (
        <a href="#" className="nav-link" onClick={props.onClick}>
          {props.icon && <i className={`bi bi-${props.icon} me-2`}></i>}
          {props.children}
        </a>
      )}
    </li>
  );
};

const NavItemDropdown = (props: {
  title: ReactNode;
  children: ReactNode;
  id: string;
}) => {
  const [toggleDropdown] = useBootStrapDropdown(props.id);

  return (
    <div className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id={props.id}
        role="button"
        aria-expanded="true"
        data-bs-toggle="dropdown"
        onClick={toggleDropdown}
      >
        {props.title}
      </a>
      <div
        className="dropdown-menu dropdown-menu-end"
        aria-labelledby="profileMenu"
      >
        {props.children}
      </div>
    </div>
  );
};

const DropDownItem = (props: {
  to?: string;
  onClick?: () => void;
  children: ReactNode;
  icon?: string;
}) => {
  return (
    <li>
      {props.to && (
        <Link to={props.to} className="dropdown-item">
          {props.icon && <i className={`bi bi-${props.icon} me-2`}></i>}
          {props.children}
        </Link>
      )}
      {props.onClick && (
        <a href="#" className="dropdown-item" onClick={props.onClick}>
          {props.icon && <i className={`bi bi-${props.icon} me-2`}></i>}
          {props.children}
        </a>
      )}
    </li>
  );
};

const Divider = () => (
  <li>
    <hr className="dropdown-divider" />
  </li>
);

export { NavItem, NavItemDropdown, DropDownItem, Divider };
