import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import moment from "moment";
import "moment/locale/ja";

// components
import { Modal } from "./Modal";

// hooks
import { useBootStrapModal } from "../hooks/useModal";

import { APP_SETTINGS } from "../setting";

import {
  listAssetFile,
  FileInfos,
  uploadAssetFile,
  deleteAssetFile,
} from "../infrastructure/assetRepository";

const h = (s: number) =>
  s > 1024
    ? `${Math.floor(s / 1024)} kB`
    : s > 1024 * 1024
    ? `${Math.floor(s / 1024 / 1024)} MB`
    : s > 1024 * 1024 * 1024
    ? `${Math.floor(s / 1024 / 1024 / 2024)} GB`
    : `${s} B`;

const getFiles = (
  uid: string,
  sketchId: string,
  setFileInfos: React.Dispatch<React.SetStateAction<FileInfos>>
) => listAssetFile(uid, sketchId).then((f) => setFileInfos(f));

type PropType = {
  id: string;
  onCloseReq?: () => void;
  uid: string;
  sketchId: string;
};

export const AssetsModal = ({ id, onCloseReq, uid, sketchId }: PropType) => {
  const [openModal, closeModal] = useBootStrapModal(id, onCloseReq);
  useEffect(() => {
    openModal();
  }, []);

  const [fileInfos, setFileInfos] = useState<FileInfos>([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!uid || !sketchId) return;
    getFiles(uid, sketchId, setFileInfos);
  }, [uid, sketchId]);

  const onDrop = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        if (file.size > APP_SETTINGS.maxFileSize)
          setMessage("ファイルサイズが大きすぎます。");
        else setMessage("");

        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const binaryStr = reader.result as ArrayBuffer;
          uploadAssetFile(uid, sketchId, file.name, binaryStr).then(() => {
            getFiles(uid, sketchId, setFileInfos);
          });
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [sketchId, uid]
  );

  const remove = async (filename: string) => {
    await deleteAssetFile(uid, sketchId, filename);
    getFiles(uid, sketchId, setFileInfos);
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    onDrop,
  });

  return (
    <Modal
      title="外部ファイル"
      id={id}
      footer={
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
        >
          閉じる
        </button>
      }
    >
      <div {...getRootProps()} className="col">
        <input {...getInputProps()} />

        <div id="fileuploader">
          <table className="table table-hover w-100">
            <thead>
              <tr>
                <th style={{ width: 250 }}>ファイル名</th>
                <th>サイズ</th>
                <th>更新日時</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {fileInfos.length !== 0 &&
                fileInfos.map((fileInfo) => (
                  <tr key={fileInfo.filename}>
                    <td>
                      <a href="#!" className="text-decoration-none">
                        📄 {fileInfo.filename}
                      </a>
                    </td>
                    <td align="right">{h(fileInfo.size)}</td>
                    <td>{moment(fileInfo.createdAt).fromNow()}</td>
                    <td onClick={() => remove(fileInfo.filename)}>🗑️</td>
                  </tr>
                ))}
              <tr>
                <td colSpan={4} align="center">
                  ファイルをドラッグ＆ドロップして
                  <a href="#" onClick={open}>
                    アップロード
                  </a>
                  できます。
                </td>
              </tr>
            </tbody>
          </table>
          {isDragActive && (
            <div
              id="uploading"
              className="h-100 w-100 d-flex justify-content-center align-items-center"
            >
              <p>ファイルをドロップするとアップロードできます。</p>
            </div>
          )}
        </div>
        <div className="text-danger">{message}</div>
      </div>
    </Modal>
  );
};
