import React, { useEffect, useState } from "react";

type SketchMessage =
  | {
      type: "error";
      load: {
        message: string;
        lineno: number;
        colno: number;
      };
    }
  | {
      type: "log";
      load: {
        message: string;
      };
    };

export const useConsole = () => {
  const [err, setErr] = useState<SketchMessage[]>([]);

  useEffect(() => {
    const listener = (e: any) => {
      if (typeof e.data !== "string") {
        return;
      }
      try {
        const data = JSON.parse(e.data) as SketchMessage;
        if (data.type === "log") {
          setErr((err_) => [...err_, data]);
        } else if (data.type === "error") {
          setErr((err_) => [...err_, data]);
        }
      } catch (e) {}
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  const message: string = err
    .map(
      (d) =>
        (d.type === "log" && d.load.message) ||
        (d.type === "error" &&
          `[${d.load.lineno}:${d.load.colno}] ${d.load.message}`)
    )
    .join("\n");

  const getMessages = () => message;
  const clearMessages = () => setErr([]);

  return { getMessages, clearMessages };
};
