import { APP_SETTINGS } from "../setting";

import { getApp } from "firebase/app";

import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  FirestoreDataConverter,
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, getBlob } from "firebase/storage";

import { UpdatedUser, User } from "../domain/user";

type UserDoc = User;

const converter: FirestoreDataConverter<UserDoc> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options) as UserDoc;
    return data;
  },
};

export const getUser = async (uid: string): Promise<User> => {
  const db = getFirestore();
  const userDoc = await getDoc(
    doc(db, `Users/${uid}`).withConverter(converter)
  );
  const user = userDoc.data();
  if (!user) throw Error("no user");
  return user;
};

export const updateUserInfo = async (
  uid: string,
  updatedUser: UpdatedUser
): Promise<void> => {
  const db = getFirestore();
  await updateDoc(doc(db, `Users/${uid}`), updatedUser);
};

// export const deleteUser = async (uid: string): Promise<void> => {
//   const db = getFirestore();
//   await deleteDoc(doc(db, `Users/${uid}`));
// };

export const uploadUserProfileImage = async (
  uid: string,
  filename: string,
  scale: number,
  iconimage: Blob
): Promise<void> => {
  const storage = getStorage(getApp(), "p5-hub-profile");
  const profileImgRef = ref(
    storage,
    `Users/${uid}/TemporaryProfileImg/${filename}`
  );
  await uploadBytes(profileImgRef, iconimage, {
    customMetadata: { scale: scale.toString() },
  });
};

export const getProfileIcon = async (uid: string): Promise<string> => {
  try {
    const iconRef = ref(getStorage(), `/Users/${uid}/profileicon.png`);
    const blob = await getBlob(iconRef);
    return URL.createObjectURL(blob);
  } catch (e) {
    return "/profile.png";
  }
};

export const getProfileImage = async (uid: string): Promise<string> => {
  try {
    const imgRef = ref(getStorage(), `/Users/${uid}/profileimg.png`);
    const blob = await getBlob(imgRef);
    return URL.createObjectURL(blob);
  } catch (e) {
    return "/profile.png";
  }
};

export const getProfileImgUrl = (uid: string): string =>
  APP_SETTINGS.storageUrl + `/Users/${uid}/profileimg.png`;

export const getProfileIconUrl = (uid: string): string =>
  APP_SETTINGS.storageUrl + `/Users/${uid}/profileimg.png`;
