import { atom, useRecoilState } from "recoil";

const defaultAuth = {
  isSignin: false,
  uid: "",
  email: "",
};

const authState = atom({ key: "isSignin", default: defaultAuth });

const profileIconState = atom({ key: "State", default: "/profile.png" });

const useAuth = () => {
  const [auth, setAuth] = useRecoilState(authState);

  const signin = (uid: string, email: string) => {
    setAuth({ isSignin: true, uid, email });
  };

  const signout = () => {
    setAuth(defaultAuth);
  };

  return { signin, signout };
};

export { authState, useAuth };
