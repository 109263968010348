import React, { useEffect, useRef, useState } from "react";
import { javascript } from "@codemirror/lang-javascript";
import { autocompletion, CompletionContext } from "@codemirror/autocomplete";
import CodeMirror from "@uiw/react-codemirror";
import { options } from "../codemirror/autocompletion";

import { ToolBarButton } from "../components/ToolBarButton";

import { AutoCodeModal } from "../modals/AutoCodeModal";

function myCompletions(context: CompletionContext) {
  let word = context.matchBefore(/\w*/);
  if (word == null) return null;
  if (word.from === word.to && !context.explicit) return null;
  return {
    from: word.from,
    options,
  };
}

type Props = {
  defaultCode?: string;
  onChange?: (code: string) => void;
  readonly?: boolean;
};

export const CodeEditor = ({
  defaultCode,
  onChange = () => {},
  readonly,
}: Props) => {
  const [isAutoCodeModalOpened, setAutoCodeModalOpened] = useState(false);

  const onApplyAutocode = (generatedCode: string) => {
    onChange(generatedCode);
  };

  return (
    <>
      <div className="d-flex justify-content-between p-3">
        <div>Editor</div>
        <ToolBarButton
          onClick={() => setAutoCodeModalOpened(true)}
          icon="stars"
        >
          AIアシスト
        </ToolBarButton>
      </div>
      <div className="flex-fill  overflow-scroll">
        <CodeMirror
          id="codemirror"
          value={defaultCode ?? ""}
          extensions={[
            javascript({ jsx: true }),
            autocompletion({ override: [myCompletions] }),
          ]}
          onChange={(value: any, viewUpdate: any) => {
            onChange(value);
          }}
          readOnly={readonly ?? false}
          height="100%"
        />
      </div>

      {isAutoCodeModalOpened && (
        <AutoCodeModal
          id="modal-autocode"
          onApply={onApplyAutocode}
          onCloseReq={() => setAutoCodeModalOpened(false)}
        />
      )}
    </>
  );
};
