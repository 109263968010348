import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// libraries
import moment from "moment"; // #1
import "moment/locale/ja"; // #2

import { SketchInfo } from "../domain/sketch";

// components
import { ProfileIcon } from "./ProfileIcon";

// infrastructure
import { getThumbnailUrl } from "../infrastructure/sketchRepository";

// hooks
import { useBootStrapDropdown } from "../hooks/useBootStrapDropdown";

// state
import { useRecoilValue } from "recoil";
import { tagColorsState } from "../states/tagsState";

type Props = {
  sketchInfo: SketchInfo;
  mySketch?: boolean;
  onDeleteSketch?: (sketchId: string) => void;
  onSelectTag?: (tag: string) => void;
};

export const SketchItem = ({
  sketchInfo,
  mySketch = false,
  onDeleteSketch = () => {},
  onSelectTag = () => {},
}: Props) => {
  const [thumnailUrl, setThumnailUrl] = useState("/noimage.png");
  const [toggleDropdown] = useBootStrapDropdown(
    "sketch-submenu-" + sketchInfo.sketchId
  );
  const tagColors = useRecoilValue(tagColorsState);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (sketchInfo.hasThumbnail) {
        setThumnailUrl(
          await getThumbnailUrl(sketchInfo.uid, sketchInfo.sketchId)
        );
      }
    })();
  }, [sketchInfo]);

  const openSketch = () => {
    navigate(`/viewer/${sketchInfo.sketchId}`);
  };

  const openSketchesOfUser = () => {
    navigate(`/user/${sketchInfo.uid}`);
  };

  const editSketch = () => {
    navigate(`/editor/${sketchInfo.uid}`);
  };

  const subMenu = (
    <ul className="dropdown-menu dropup">
      <li className="dropdown-item" onClick={editSketch}>
        編集
      </li>
      <li>
        <hr className="dropdown-divider" />
      </li>
      <li
        className="dropdown-item"
        onClick={() => onDeleteSketch(sketchInfo.sketchId)}
      >
        削除
      </li>
    </ul>
  );

  return (
    <div className="col mb-4 sketch-item" key={sketchInfo.sketchId}>
      <div className="w-100 mb-2 sketch-thumbnail" onClick={openSketch}>
        <img className="w-100" src={thumnailUrl} alt={sketchInfo.title} />
      </div>
      <div className="d-flex mb-2">
        <div className="me-2 sketch-profile" onClick={openSketchesOfUser}>
          <ProfileIcon
            uid={sketchInfo.uid}
            hasProfileImg={sketchInfo.hasProfileImg}
          />
        </div>
        <div className="flex-fill">
          <div className="sketch-title" onClick={openSketch}>
            {sketchInfo.title}
          </div>
          <div className="sketch-uname" onClick={openSketchesOfUser}>
            {sketchInfo.uname}・{moment(sketchInfo.updatedAt).fromNow()}
          </div>
        </div>
        {mySketch && (
          <>
            <div
              className="dropdown-toggle"
              id={"sketch-submenu-" + sketchInfo.sketchId}
              role="button"
              aria-expanded="true"
              data-bs-toggle="dropdown"
              onClick={toggleDropdown}
            >
              <i className="bi bi-three-dots-vertical" />
            </div>
            {subMenu}
          </>
        )}
      </div>
      <div className="d-flex flex-wrap ">
        {sketchInfo.tags.map((tag) => (
          <div
            className=" me-1 sketch-tag"
            key={`sketch-${tag}`}
            style={{ backgroundColor: tagColors[tag] }}
          >
            {tag}
          </div>
        ))}
      </div>{" "}
    </div>
  );
};
