export const useScreenshot = (
  onChangeScreeshot: (screenshot: Blob | null) => void
) => {
  const takeScreenshot = () => {
    const size = 480;
    const iframe = document.getElementById(
      "preview-iframe"
    ) as HTMLIFrameElement;
    if (!iframe || !iframe.contentWindow || !iframe.contentDocument) return;
    const innerDoc = iframe.contentWindow.document;

    const canvas =
      (innerDoc.getElementById("defaultCanvas0") as HTMLCanvasElement) ??
      (innerDoc.getElementById("p5Canvas6") as HTMLCanvasElement);
    if (!canvas) return;

    const [w, h] = [canvas.width, canvas.height];

    const canvasImageUrl = canvas.toDataURL("image/png");

    const resizeCanvas = document.createElement("canvas");
    const ctx = resizeCanvas.getContext("2d");
    if (!ctx) return;

    const image = new Image();
    image.src = canvasImageUrl;
    image.onload = function () {
      resizeCanvas.width = size; // 新しい幅
      resizeCanvas.height = size; // 新しい高さ
      if (w > h)
        ctx.drawImage(
          image,
          -(((w - h) / h) * size) / 2,
          0,
          size * (w / h),
          size
        );
      else
        ctx.drawImage(
          image,
          0,
          -(((h - w) / w) * size) / 2,
          size,
          size * (h / w)
        );
      resizeCanvas.toBlob(onChangeScreeshot, "image/png");
    };
  };

  return takeScreenshot;
};
