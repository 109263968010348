import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

// domain
import { initialLocalSketch, LocalSketch, SketchInfo } from "../domain/sketch";

// component
import { Sidebar } from "../components/Sidebar/Sidebar";
import { Navbar } from "../components/Navbar";
import { Preview } from "../components/Preview";
import { ToolBarButton } from "../components/ToolBarButton";
import { Qrode } from "../components/Qrcode";

// template
import { Main } from "../templates/Main";
import { FullScreen } from "../templates/FullScreen";

// state
import { useRecoilState } from "recoil";
import { authState } from "../states/authState";

import { useFullScreen } from "../components/FullScreen";

// infrastructure
import {
  getSketchMainJs,
  getSketchInfoFromSketchId,
} from "../infrastructure/sketchRepository";

import { APP_SETTINGS } from "../setting";

type PropTypes = {
  viewmode?: "window" | "fullscreen";
};

export const Viewer = ({ viewmode = "window" }: PropTypes) => {
  const [playState, setPlayState] = useState<boolean>(true);

  const { sketchId, shortId } = useParams();
  const navigate = useNavigate();

  const [auth] = useRecoilState(authState);

  const [sketchInfo, setSketchInfo] = useState<SketchInfo | undefined>(
    undefined
  );
  const [sketch, setSketch] = useState<LocalSketch>(initialLocalSketch);
  const [previewCode, setPreviewCode] = useState<string>("");
  const { enterFullScreen, exitFullScreen, isFullScreen } = useFullScreen(
    viewmode === "fullscreen"
  );

  useEffect(() => {
    if (sketchId || shortId) {
      // 既存のスケッチをロードする
      (async () => {
        const sketchInfo = await getSketchInfoFromSketchId(
          sketchId ?? shortId!,
          auth.uid
        );
        setSketchInfo(sketchInfo);

        const code = await getSketchMainJs(sketchInfo.uid, sketchInfo.sketchId);
        setSketch({ ...sketchInfo, code, thumbnail: undefined });

        setPreviewCode(code);
      })();
    }
  }, [auth.uid, sketchId, shortId]);

  const viewCode = () => {
    if (auth.isSignin) {
      navigate("/editor/" + sketchId);
    } else {
      // サインインしていない場合はサインインさせる。
      navigate(`/signin/toedit/${sketchId}`);
    }
  };

  const toolBarLeft = (
    <>
      <div>{sketch.title}</div>
    </>
  );

  const play = () => {
    setPreviewCode(sketch.code);
    setPlayState(true);
  };

  const playFullScreen = () => {
    enterFullScreen();
    setPlayState(true);
  };

  const stop = () => {
    setPreviewCode("");
    setPlayState(false);
  };

  const toolBarCenter = (
    <>
      <ToolBarButton onClick={play}>
        <i className="bi bi-play"></i>
      </ToolBarButton>
      <ToolBarButton onClick={stop}>
        <i className="bi bi-stop"></i>
      </ToolBarButton>
      <ToolBarButton onClick={playFullScreen} icon="fullscreen">
        全画面表示
      </ToolBarButton>{" "}
    </>
  );

  const toolBarRight = (
    <>
      <ToolBarButton onClick={viewCode} icon="code">
        コードを表示
      </ToolBarButton>
    </>
  );

  const contents = (
    <div className="d-flex h-100 pb-3" id="editor-container">
      <div className="flex-fill overflow-scroll d-flex flex-column justify-content-center align-items-center">
        <div className="m-0">
          {previewCode && (
            <Preview
              uid={sketchInfo?.uid}
              sketchId={sketchInfo?.sketchId}
              code={previewCode}
              plugins={sketch.plugins}
              play={playState}
            />
          )}
        </div>
      </div>
    </div>
  );

  const [largeQr, setLargeQr] = useState<boolean>(false);

  // フルスクリーンモード
  if (isFullScreen)
    return (
      <FullScreen
        sidemenuContent={
          <div className="d-flex flex-column w-100 h-100 px-5 py-5">
            <div
              className="text-center"
              onClick={() => {
                setLargeQr(!largeQr);
              }}
            >
              {sketch.shortId && (
                <Qrode
                  url={APP_SETTINGS.fullScreenUrl + sketch.shortId}
                  size={220}
                />
              )}
            </div>
            <div className="pb-5 text-center">
              <a href={APP_SETTINGS.fullScreenUrl + sketch.shortId}>
                {APP_SETTINGS.fullScreenUrl + sketch.shortId}
              </a>
            </div>
            <div>
              <h2>{sketch.title}</h2>
              <p>{sketch.desc}</p>
              <p>
                {sketch.tags.map((tag) => (
                  <span
                    className="badge bg-secondary me-1 sketch-tag"
                    key={`sketch-${tag}`}
                  >
                    {tag}
                  </span>
                ))}
              </p>
            </div>
            <div className="text-end">
              <ToolBarButton onClick={exitFullScreen}>戻る</ToolBarButton>
            </div>
          </div>
        }
        onClose={exitFullScreen}
      >
        <div className="d-flex h-100 w-100 align-items-center justify-content-center">
          {previewCode && (
            <Preview
              code={previewCode}
              plugins={sketch.plugins}
              play={playState}
            />
          )}
        </div>
      </FullScreen>
    );

  return (
    <Main
      navbar={
        <Navbar
          left={toolBarLeft}
          center={toolBarCenter}
          right={toolBarRight}
        />
      }
      contents={contents}
    />
  );
};
