import {
  getFirestore,
  doc,
  getDoc,
  onSnapshot,
  FirestoreDataConverter,
} from "firebase/firestore";

import { Tags, TagNums, TagColors } from "../domain/tags";

const tagNumConverter: FirestoreDataConverter<TagNums> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return data as TagNums;
  },
};

const tagColorsConverter: FirestoreDataConverter<TagColors> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return data as TagColors;
  },
};

export const getTags = async (): Promise<Tags> => {
  const db = getFirestore();
  const tagNumsRef = doc(db, "Tags/tagNums").withConverter(tagNumConverter);
  const tagNumsDoc = await getDoc(tagNumsRef);
  const tagNumsData = tagNumsDoc.data();
  if (!tagNumsData) return [];

  return Object.keys(tagNumsData)
    .map((tag) => ({ name: tag, num: tagNumsData[tag] }))
    .sort((a, b) => b.num - a.num)
    .map((t) => t.name);
};

export const getTagColors = async (): Promise<TagColors> => {
  const db = getFirestore();

  const tagColorsRef = doc(db, "Tags/tagColors").withConverter(
    tagColorsConverter
  );
  const tagColorsDoc = await getDoc(tagColorsRef);
  const tagColorsData = tagColorsDoc.data();
  if (!tagColorsData) return {};

  return tagColorsData;
};

export const addTags = () => {};

export const setTagSnapshotListener = (
  onTagsData: (tagsData: Tags) => void,
  onTagCloorsData: (tagColorsData: TagColors) => void
) => {
  const db = getFirestore();

  const tagColorsRef = doc(db, "Tags/tagColors").withConverter(
    tagColorsConverter
  );
  const tagNumsRef = doc(db, "Tags/tagNums").withConverter(tagNumConverter);

  onSnapshot(tagNumsRef, (doc) => {
    if (!doc.exists()) return;
    const docData = doc.data();
    const tagsData: Tags = Object.keys(docData)
      .map((tag) => ({ name: tag, num: docData[tag] }))
      .sort((a, b) => b.num - a.num)
      .map((t) => t.name);
    onTagsData(tagsData);
  });
  onSnapshot(tagColorsRef, (doc) => {
    if (!doc.exists()) return;
    const tagColorsData = doc.data();
    onTagCloorsData(tagColorsData);
  });
};
