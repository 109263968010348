const defaultCode = `\
function setup() {
  createCanvas(500, 500);
}

function draw() {

}
`;

export const APP_SETTINGS = {
  fullScreenUrl: "https://egocoro.app/f/",
  storageUrl: "https://storage.googleapis.com/p5-hub.appspot.com/",
  maxFileSize: 50 * 1024 * 1024, //50MB
  defaultCode,
  defaultTitle: "untitled",
};

export const PLUGINS: { [key: string]: string } = {
  "p5SimpleAR-0.7.0":
    "https://tetunori.github.io/p5.simpleAR/dist/v0.7.0/p5SimpleAR.js",
};
